import React from "react"

const ContactBox = ({ data, name }) => (
  <div className="contactForm">
    <form
      className="contact_form"
      name={name}
      method="POST"
      action="/mail-sendt"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      encType="multipart/form-data"
    >
      <input type="hidden" name={name} value={name} />

      <div className="upper_wrapper">
        <div className="hiddenInput">
          <label htmlFor="tilmeldning">{data}</label>

          <input
            type="text"
            name={`tilmeldning`}
            value={`Tilmeldning til ${data}`}
            id={`tilmeldning${data}`}
            required
          />
        </div>

        <div className="input_wrapper">
          <label htmlFor="navn">Navn *</label>

          <input type="text" name="navn" id={`navn${data}`} required />
        </div>
        <div className="input_wrapper">
          <label htmlFor="email">Email *</label>

          <input
            type="email"
            name="email"
            id={`email${data}`}
            data-error="Indtast venligst din e-mail"
            required
            className="marginBottom"
          />
        </div>

        {/* <div className="input_checkbox">
          <input
            type="checkbox"
            name="accepter"
            id={`accept${data}`}
            data-error="Accepter venligst vores betingelser"
            required
          />
          <label htmlFor={`accept${data}`}>
            Jeg har læst og accepteret SIS betingelser.
          </label>
        </div> */}
      </div>

      <div className="buttonPosition">
        <input
          className="sendButton buttonTemp"
          type="submit"
          value="Tilmeld"
        />
      </div>
    </form>
  </div>
)

export default ContactBox
